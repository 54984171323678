@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap);


.big {
    width: 100%;
}

    .big .hg-button {
        height: 80px;
        font-size: 24px;
    }

.small {
    width: 100%;
}

    .small .hg-button {
        height: 40px;
        font-size: 14px;
    }

.medium {
    width: 100%;
}

    .medium .hg-button {
        height: 60px;
        font-size: 22px;
    }

.numpad {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

    .numpad .hg-button {
        height: 80px;
        font-size: 30px;
        width: 20px;
    }

.custom-fields-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}

.custom-fields-button-container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
    align-items: center;
}

.custom-fields-button {
    margin-right: 20px;
}

.custom-text-field {
    margin: 30px 30px 0 30px;
}

.main-fields-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%
}

.continue2-button {
    position: absolute;
    top: 350px;
    left: 1300px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.employee-card-container {}

.employee-card-container-with-photo {}

.employee-card-icon {
    width: 50px;
    height: 50px;
}

.employee-card-text {
    padding: 5px;
    word-wrap: break-word;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.employee-cards-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-v1::after {
    border: 2px solid rgb(96, 94, 92) !important;
}

.search-v2::after {
    border-bottom: 2px solid rgb(96, 94, 92) !important;
}

.ms-SearchBox-iconContainer {
    font-size: 24px;
    margin: 10px;
}

.employee-info {
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.employee-info-no-photo {
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.employee-info {
    font-size: 20px;
}

.employee-info {
    font-size: 16px;
    padding: 0 10px;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
}

.employee-info-no-photo {
    font-size: 20px;
}

.employee-info-no-photo {
    font-size: 16px;
    padding: 0 10px;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
}

.employee-image {
    max-height: 150px;
    max-width: 120px;
}

/*===============================================================================*/
/* Phone Friendly CSS */
/*===============================================================================*/
.PhoneFriendly-employee-cards-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 75vw;
    height: 46vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.invisible {
    visibility: hidden;
}
.flow-card-icon {
  font-size: 10vh;
  cursor: pointer;
}

.front-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
}

.custom-fields-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.custom-text-field {
  margin: 30px 30px 0 30px;
}

.main-fields-container {
     display: flex;
     flex-flow: column;
     width: 100%;
     height: 100%
}

.main-text-fields-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.main-fields-button-container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
    align-items: center;
}

.main-fields-button {
    margin-right: 20px;
}

.main-text-field {
    margin: 30px;
}

.ms-Label {
    font-size: 22px;
    font-family: Roboto;
    text-align: center;
}

.continue2-button {
    position: absolute;
    top: 350px;
    left: 1300px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.Reiswolf-txtfield {
    position: absolute;
    top: 400px;
    left: 180px;
}


.Reisswolf-title {
    position: absolute;
    top: 100px;
    left: 210px;
    font-size: 70px;
    font-weight: 700;
}

.Reisswolf-secondtitle {
    position: absolute;
    top: 210px;
    left: 210px;
    font-size: 40px;
    font-weight: 100;
}


.Reisswolf-inputtext {
    position: absolute;
    top: 380px;
    left: 210px;
    font-size: 30px;
    font-weight: 100;
}
.message-container {
    margin: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.rovsing_image {
    margin-top: -150px;
}
.number-text-field-container {
    margin: 30px;
    display: flex;
    justify-content: center;
}
.header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 70px;
    height: 200px;
    width: 100%;
    margin-top: -150px;
}

.number-text-field-container {
    margin: 30px;
    display: flex;
    justify-content: center;
}

/*===============================================================================*/
/* Phone Friendly CSS */
/*===============================================================================*/
.PhoneFriendly-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 6vh;
    width: 100%;
    margin-top: -10vh;
}
body {
}


.appointments-Container {
    max-height: 700px;
    max-width: 800px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-bottom: 1px solid #B9B9B9;
    position: absolute;
    top: 270px;
    left: 120px;
}

.appointments-Tablebody {
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    table-layout: fixed;
    font-size: 15px;
}

.appointments-Table {
    display: block;
    border-collapse: collapse;
    border: 0px solid;
    display: table;
    table-layout: fixed;
    overflow-x: hidden;
}

thead, tbody {
    display: table;
    table-layout: fixed;
    overflow-x: hidden;
}

.appointment-row {
    height: 80px;
    width: 100%;
    display: table;
    table-layout: fixed;
    overflow-x: hidden;
}

.appointment-rowheader {
    height: 80px;
    width: 100%;
    display: table;
    table-layout: fixed;
}

td, th {
    padding: 15px;
    border: 1px solid #B9B9B9
}

.column1 {
    width: 272px;
}

.column2 {
    width: 274px;
}

.column3 {
    width: 116px;
}


.column4 {
    width: 100px;
}

.column1h {
    width: 280px;
}

.column2h {
    width: 280px;
}

.column3h {
    width: 120px;
}


.column4h {
    width: 120px;
}

/* width */
::-webkit-scrollbar {
    width: 17px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #B9B9B9;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0D3D8B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0D3D8B;
}

.checkoutlist-container {
    width: 1920px;
}

.continue-button {
    position: absolute;
    top: 800px;
    left: 1400px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter'; 
    font-size: 60px;
    padding-top: 28px; 
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.continue-button:disabled {
    background-color: rgb(243, 242, 241);
    color: rgb(210, 208, 206);
}


.checkoutlist-title {
    position: absolute;
    top: 100px;
    left: 120px;
    font-size: 60px;
    font-weight: 700;
}

.checkoutlist-description {
    position: absolute;
    top: 190px;
    left: 120px;
    font-size: 30px;
    font-weight: 100;
}


.confirm-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 1920px;
    height: 1080px;
    visibility: hidden;
}


.confirm-button {
    position: absolute;
    bottom: 200px;
    right: 400px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.back-button {
    position: absolute;
    bottom: 200px;
    left: 400px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.confirm-Text {
    position:relative;
    top: 250px;
    text-align: center;
    font-size: 80px;
}

.confirm-name {
    position: relative;
    top: 250px;
    text-align: center;
    font-size: 80px;
    font-weight: 900;
}

.confirm-company {
    position: relative;
    top: 250px;
    text-align: center;
    font-size: 55px;
}


.logo {
    position: absolute;
    top: -50px;
    left: 1650px;
    width: 300px;
    height: 300px;
    object-fit: contain;
}
.check-box-and-message {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.condition-check-box {
    width: 66px;
    height: 66px;
    border: 3px solid black;
}

.condition-message {
    text-align: center;
    font-size: 30px;
}

.button-text-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.main-fields-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%
}

.main-text-fields-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.condition-continue2-button {
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.Reisswolf-title {
    position: absolute;
    top: 100px;
    left: 210px;
    font-size: 70px;
    font-weight: 700;
}

.Reisswolf-secondtitle {
    position: absolute;
    top: 210px;
    left: 210px;
    font-size: 40px;
    font-weight: 100;
}


.Reisswolf-inputtext {
    position: absolute;
    top: 380px;
    left: 210px;
    font-size: 30px;
    font-weight: 100;
}

/*===============================================================================*/
/* Phone Friendly CSS */
/*===============================================================================*/
.PhoneFriendly-condition-message {
    text-align: center;
    font-size: 4vh;
}

.PhoneFriendly-condition-check-box {
    margin-left: 11vw;
    width: 7vh;
    height: 7vh;
    border: 3px solid black;
}


.video-container {
    margin-top: 0px;
    width: 1520px;
    height: 855px;
}

.check-box-and-message {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.condition-message2 {
    text-align: center;
    font-size: 30px;
}

.condition-check-box {
    width: 66px;
    height: 66px;
    border: 3px solid black;
}

.button-text-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.main-fields-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%
}

.main-text-fields-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.condition-continue2-button {
    position: absolute;
    top: 50px;
    left: 1250px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.Reisswolf-title {
    position: absolute;
    top: 100px;
    left: 210px;
    font-size: 70px;
    font-weight: 700;
}

.Reisswolf-secondtitle {
    position: absolute;
    top: 210px;
    left: 210px;
    font-size: 40px;
    font-weight: 100;
}


.Reisswolf-inputtext {
    position: absolute;
    top: 380px;
    left: 210px;
    font-size: 30px;
    font-weight: 100;
}

.Reisswolf-video-container {
    margin-top: -100px;
    width: 1340px;
    height: 754px;
}

.Reisswolf-video-description {
    position: absolute;
    top: 60px;
    left: 650px;
    width: 550px;
    text-align: left;
    font-size: 24px;
    line-height: 1;
}

.Hin-video-container {
    margin-top: 40px;
    width: 1520px;
    height: 855px;
}

.Kjaergaard-video-container {
    margin-top: 40px;
    width: 1520px;
    height: 855px;
}

/*===============================================================================*/
/* Phone Friendly CSS */
/*===============================================================================*/
.PhoneFriendly-video-container {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 75vw;
    height: 75vh;
}

.PhoneFriendly-condition-message2 {
    margin-top: 1vh;
    text-align: left;
    font-size: 3.5vh;
    margin-right: 5vw;
}

.PhoneFriendly-condition-check-box {
    margin-left: 15vw;
    width: 7vh;
    height: 7vh;
    border: 3px solid black;
}

.rowDepartment {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
}

.columnDepartment {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.main-fields-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}

.main-text-fields-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.Reisswolf-title {
    position: absolute;
    top: 100px;
    left: 210px;
    font-size: 70px;
    font-weight: 700;
}

.pdf-container {
    margin-top: 0px;
    width: 1520px;
    height: 855px;
}

.check-box-and-message {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.condition-message2 {
    text-align: center;
    font-size: 30px;
}

.condition-check-box {
    width: 66px;
    height: 66px;
    border: 3px solid black;
}

.button-text-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.main-fields-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%
}

.main-text-fields-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.condition-continue2-button {
    position: absolute;
    top: 50px;
    left: 1250px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.Reisswolf-title {
    position: absolute;
    top: 100px;
    left: 210px;
    font-size: 70px;
    font-weight: 700;
}

.Reisswolf-secondtitle {
    position: absolute;
    top: 210px;
    left: 210px;
    font-size: 40px;
    font-weight: 100;
}


.Reisswolf-inputtext {
    position: absolute;
    top: 380px;
    left: 210px;
    font-size: 30px;
    font-weight: 100;
}

.Reisswolf-pdf-container {
    margin-top: -100px;
    width: 1340px;
    height: 754px;
}

.Reisswolf-pdf-description {
    position: absolute;
    top: 60px;
    left: 650px;
    width: 550px;
    text-align: left;
    font-size: 24px;
    line-height: 1;
}

.Hin-pdf-container {
    margin-top: 40px;
    width: 1520px;
    height: 855px;
}

.Kjaergaard-pdf-container {
    margin-top: 40px;
    width: 1520px;
    height: 855px;
}

.Flensted-pdf-container {
    margin-top: -50px;
    width: 1520px;
    height: 620px;
}

.Meneta-pdf-container {
    height: 550px;
}

/*===============================================================================*/
/* Phone Friendly CSS */
/*===============================================================================*/
.PhoneFriendly-pdf-container {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 75vw;
    height: 75vh;
}

.PhoneFriendly-condition-message2 {
    margin-top: 1vh;
    text-align: left;
    font-size: 3.5vh;
    margin-right: 5vw;
}

.PhoneFriendly-condition-check-box {
    margin-left: 15vw;
    width: 7vh;
    height: 7vh;
    border: 3px solid black;
}

.main-fields-container {
     display: flex;
     flex-flow: column;
     width: 100%;
     height: 100%;
}

.main-text-fields-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.main-fields-button-container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
    align-items: center;
}

.main-fields-button {
    margin-right: 20px;
}

.main-text-field {
    margin: 30px;
}

.ms-Label {
    font-size: 22px;
    font-family: Roboto;
    text-align: center;
}

.continue2-button {
    position: absolute;
    top: 350px;
    left: 1300px;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    padding: 15px;
    word-wrap: break-word;
    font-family: 'Inter';
    font-size: 60px;
    padding-top: 28px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.Reiswolf-txtfield {
    position: absolute;
    top: 400px;
    left: 180px;
}


.Reisswolf-title {
    position: absolute;
    top: 100px;
    left: 210px;
    font-size: 70px;
    font-weight: 700;
}

.Reisswolf-secondtitle {
    position: absolute;
    top: 210px;
    left: 210px;
    font-size: 40px;
    font-weight: 100;
}


.Reisswolf-inputtext {
    position: absolute;
    top: 380px;
    left: 210px;
    font-size: 30px;
    font-weight: 100;
}
body {
}

.inputs-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    position: relative
}

.input-fields-and-next-button-container {
    position: relative
}

.visitor-information-next-button{
    position: absolute;
    right: 0px;
    top: 50%;
}
#root {
    height: 100%;
    width: 100%;
}

.ultra-turbo-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.front-logo-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-v1 {
    max-height: 100px;
    margin: 20px 0 0 20px;
}

.logo-v2 {
    max-height: 100px;
    margin: 20px 20px 0 0;
}

.logo-v3 {
    max-height: 100px;
    margin: 20px 20px 0 0;
}

.front-logo {
    max-height: 400px;
}

.front-logo-container > div {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.ms-TextField-fieldGroup::after {
    border: 1px solid rgb(96, 94, 92) !important;
}

.ms-TextField-wrapper::after {
    border-bottom: 2px solid rgb(96, 94, 92) !important;
}

img[src=""] {
    display: none;
}

/*===============================================================================*/
/* Phone Friendly CSS */
/*===============================================================================*/
.PhoneFriendly-ultra-turbo-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.PhoneFriendly-logo {
    max-height: 12vh;
    margin: 1vh 0 0 1vh;
}

/*===============================================================================*/
/* Imported Fonts */
/*===============================================================================*/
.font-frankling {
    font-family: 'Libre Franklin';
}

.font-montserrat {
    font-family: 'Montserrat';
}

.font-soho {
    font-family: 'Soho';
}

.font-calibri {
    font-family: 'Open Sans';
}

.font-2dgraphic {
    font-family: 'Kode Mono';
}
.clock-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clock-container p {
    margin: 0;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(/static/media/Roboto-Regular.f36638c2.ttf) format('truetype');
}
@font-face {
  font-family: 'Soho';
  src: local('Soho'), url(/static/media/SohoGothicStd-Regular.3f1f029d.otf) format('truetype');
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

