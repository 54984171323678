@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Soho';
  src: local('Soho'), url(./fonts/SohoGothicStd-Regular.otf) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
